.copy-text {
  width: 100%;
  display: inline-flex;
  padding: var(--copy-text-pd);

  background-color: var(--copy-text-bg-color);

  &__body {
    width: 100%;
    display: inline-flex;
    align-items: center;
    column-gap: var(--copy-text-body-column-gap);

    .text {
      width: 100%;
      text-align: left;
      word-break: break-all;
      word-wrap: break-word;
    }
  }
  &__button {
    display: flex;
    flex: 0 0 var(--copy-text-button-flex);
  }
}
.copy-item{
  width: 100%;
  display: inline-flex;
  justify-content: center;
  &__children{
    margin-right: 1em;
  }
}
