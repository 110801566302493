@import "../../assets/sass/mixins.scss";
.page {
  display: flex;
  flex: 1 1 auto;
  box-sizing: border-box;
  @include space(padding, top, level6);

  .container {
    display: flex;
  }

  &__body {
    box-sizing: border-box;
    padding: var(--page-body-pd-mob);

    background-color: var(--page-body-bg-color);
    box-shadow: var(--page-body-box-shadow);
    border-radius: var(--page-body-border-r);

    &--width-full {
      width: 100%;
    }

    @include screen(md) {
      padding: var(--page-body-pd-md);
      width: 100%;
    }
  }
  &__content {
    @include space(padding, top, level3);
  }
}
