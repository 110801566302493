@import "../../../assets/sass/mixins.scss";
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--avatar-size);
  height: var(--avatar-size);
  flex: 0 0 var(--avatar-size);

  background-color: var(--avatar-bg-color);
  border-radius: 50%;

  &--size-s {
    width: var(--avatar-size-s);
    height: var(--avatar-size-s);
    flex: 0 0 var(--avatar-size-s);
  }
  &--size-m {
    width: var(--avatar-size-m);
    height: var(--avatar-size-m);
    flex: 0 0 var(--avatar-size-m);
  }
  &--size-l {
    width: var(--avatar-size-l);
    height: var(--avatar-size-l);
    flex: 0 0 var(--avatar-size-l);
  }
  &--color-blue {
    background-color: var(--avatar-bg-color-blue);
  }
  &--color-orange {
    background-color: var(--avatar-bg-color-orange);
  }
  &--color-yellow {
    background-color: var(--avatar-bg-color-yellow);
  }
  &--color-red {
    background-color: var(--avatar-bg-color-red);
  }
  &--color-green {
    background-color: var(--avatar-bg-color-green);
  }
}
