@import "../../../assets/sass/mixins.scss";
.flag-dropdown {
  display: inline-flex;
  box-sizing: border-box;

  &__control {
    padding: var(--flag-dropdown-control-pd) !important;
    padding-right: 0;
    box-shadow: none !important;
    flex-wrap: nowrap !important;

    cursor: pointer !important;
    border: 1px solid var(--flag-dropdown-control-border-color) !important;
    border-radius: var(--flag-dropdown-control-border-r) !important;

    &:hover {
      background-color: var(--flag-dropdown-control-hover-bg-color);
      border-color: var(--flag-dropdown-control-hover-border-color) !important;
    }
  }
  &__input-container {
    display: none !important;
  }
  &__value-container {
    padding: 0 !important;
  }
  &__single-value {
    display: flex;
    margin: 0 !important;
  }
  &__indicator {
    padding: var(--flag-dropdown-indicator-pd) !important;
    margin-left: var(--flag-dropdown-indicator-m-l);

    cursor: pointer !important;

    .icon {
      fill: var(--flag-dropdown-indicator-icon-fill);
    }
  }
  &__indicator-separator {
    display: none;
  }
  &__menu {
    margin: var(--flag-dropdown-menu-m) !important;

    background-color: var(--flag-dropdown-menu-bg-color) !important;
    border: 1px solid var(--flag-dropdown-menu-border-color) !important;
    border-radius: 0 0 var(--flag-dropdown-menu-border-r) var(--flag-dropdown-menu-border-r) !important;
    border-top: none !important;
    box-shadow: none !important;
  }
  &__menu-list {
    padding: 0 !important;
  }
  &__option {
    position: relative;

    display: flex !important;
    padding: var(--flag-dropdown-option-pd) !important;
    margin-bottom: 3px;

    cursor: pointer !important;

    &:hover {
      background-color: var(--flag-dropdown-bg-color-hover) !important;
    }
    &:last-child {
      margin-bottom: 0;

      border-radius: 0 0 var(--langs-option-border-radius) var(--langs-option-border-radius);
    }
  }
  &__option--is-selected {
    background-color: transparent !important;

    &::before {
      display: none;
    }
  }
  &__option--is-focused {
    background-color: transparent !important;
  }
  &__control--menu-is-open {
    border-radius: var(--flag-dropdown-control-border-r) var(--flag-dropdown-control-border-r) 0 0 !important;
    border-bottom: none !important;

    border-color: var(--flag-dropdown-control-active-border-color) !important;
    background-color: var(--flag-dropdown-control-active-bg-color) !important;

    .flag-dropdown__indicator {
      transform: rotate(180deg);
    }
  }
}
