.loader {
  display: inline-flex;

  &__img {
    display: flex;

    animation: 1s linear 0s normal none infinite running rot;
  }
}
@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
