.column--column-gap--level1 {
  @include space(column-gap, all, level1);
}
.column--column-gap--level2 {
  @include space(column-gap, all, level2);
}
.column--column-gap--level3 {
  @include space(column-gap, all, level3);
}
.column--column-gap--level4 {
  @include space(column-gap, all, level4);
}
.column--column-gap--level5 {
  @include space(column-gap, all, level5);
}
.column--column-gap--level6 {
  @include space(column-gap, all, level6);
}
.column--column-gap--level7 {
  @include space(column-gap, all, level7);
}
.column--column-gap--level8 {
  @include space(column-gap, all, level8);
}
.column--column-gap--level9 {
  @include space(column-gap, all, level9);
}
.column--column-gap--level10 {
  @include space(column-gap, all, level10);
}
// @each $breakpoint in map-keys($grid-breakpoints) {
//   @include media-breakpoint-up($breakpoint) {
//     $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
//     .column-#{$infix}-column-gap-xs {
//       column-gap: var(--space-xs);
//     }
//     .column-#{$infix}-column-gap-2xs {
//       column-gap: var(--space-2xs);
//     }
//     .column-#{$infix}-column-gap-s {
//       column-gap: var(--space-s);
//     }
//     .column-#{$infix}-column-gap-2s {
//       column-gap: var(--space-2s);
//     }
//     .column-#{$infix}-column-gap-3s {
//       column-gap: var(--space-3s);
//     }
//     .column-#{$infix}-column-gap-m {
//       column-gap: var(--space-m);
//     }
//     .column-#{$infix}-column-gap-2m {
//       column-gap: var(--space-2m);
//     }
//     .column-#{$infix}-column-gap-3m {
//       column-gap: var(--space-3m);
//     }
//     .column-#{$infix}-column-gap-4m {
//       column-gap: var(--space-4m);
//     }
//     .column-#{$infix}-column-gap-l {
//       column-gap: var(--space-l);
//     }
//     .column-#{$infix}-column-gap-2l {
//       column-gap: var(--space-2l);
//     }
//     .column-#{$infix}-column-gap-xl {
//       column-gap: var(--space-xl);
//     }
//   }
// }
