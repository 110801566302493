.icon-text {
  display: inline-flex;

  &--gap-esm {
    gap: var(--icon-text-gap-esm);
  }
  &--gap-sm {
    gap: var(--icon-text-gap-sm);
  }
  &--gap-m {
    gap: var(--icon-text-gap-m);
  }
  &--gap-lg {
    gap: var(--icon-text-gap-lg);
  }
  &--gap-xl {
    gap: var(--icon-text-gap-xl);
  }
  &--gap-2xl {
    gap: var(--icon-text-gap-2xl);
  }
  &--align-start {
    align-items: flex-start;
  }
  &--align-end {
    align-items: flex-end;
  }
  &--align-center {
    align-items: center;
  }
  &--align-normal {
    align-items: normal;
  }
  &--justify-center {
    width: 100%;
    justify-content: center;
  }
  &--size-base {
    .icon {
      flex: 0 0 var(--icon-size-base);
    }
  }
  &--size-esm {
    .icon {
      width: var(--icon-size-esm);
      height: var(--icon-size-esm);
      flex: 0 0 var(--icon-size-esm);
    }
  }
  &--size-sm {
    .icon {
      width: var(--icon-size-sm);
      height: var(--icon-size-sm);
      flex: 0 0 var(--icon-size-sm);
    }
  }
  &--size-md {
    .icon {
      width: var(--icon-size-md);
      height: var(--icon-size-md);
      flex: 0 0 var(--icon-size-md);
    }
  }
  &--size-lg {
    .icon {
      width: var(--icon-size-lg);
      height: var(--icon-size-lg);
      flex: 0 0 var(--icon-size-lg);
    }
  }
  &--size-xl {
    .icon {
      width: var(--icon-size-xl);
      height: var(--icon-size-xl);
      flex: 0 0 var(--icon-size-xl);
    }
  }
  &--size-normal {
    .icon {
      width: auto;
      height: auto;
      flex: 0 1 auto;
    }
  }
}
