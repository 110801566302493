@import "../../assets/sass/mixins.scss";
.title {
  @include space(padding, bottom, level1);

  &__lead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 6px;
    @include space(padding, top, level2);

    .icon {
      flex: 0 0 var(--icon-size-base);
    }
  }
}
