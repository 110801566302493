.link {
  color: var(--link-color);
  text-decoration: underline;

  cursor: pointer;

  transition: all var(--transition) ease 0s;

  .text {
    color: currentColor;
  }

  .icon {
    fill: currentColor;
  }

  &:hover {
    color: var(--link-hover-color);
  }
}
