/* Color */
.text--color-primary {
  color: var(--font-color-primary);
}
.text--color-secondary {
  color: var(--font-color-secondary);
}
.text--color-third {
  color: var(--font-color-third);
}
.text--color-four {
  color: var(--font-color-four);
}
.text--color-danger {
  color: var(--font-color-danger);
}
