@import "../../assets/sass/mixins.scss";
.header {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  height: var(--header-height);
  padding-left: 20px;
  padding-right: 20px;

  background-color: var(--header-background-color);
  border-bottom: var(--header-border-width) solid var(--header-border-color);

  @include screen(md) {
    height: var(--header-height-md);
    padding-left: 50px;
    padding-right: 50px;
  }
  @include screen(lg) {
    height: var(--header-height-lg);
    padding-left: 40px;
    padding-right: 40px;
  }
  &__left {
    flex: 0 1 auto;
  }
  &__right {
    flex: 0 1 auto;
  }
  &__actions {
    display: flex;
    align-items: center;
    column-gap: 22px;

    @include screen(md) {
      column-gap: 24px;
    }
    @include screen(lg) {
      column-gap: 34px;
    }
  }
}
