@import "../../../../assets/sass/mixins.scss";
.chat-text-area {
  position: relative;

  display: flex;
  column-gap: 16px;

  &__body {
    position: relative;
    flex: 1 1 auto;
  }
  &__area {
    display: flex;
    height: var(--input-without-body-height);
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    padding: 12px 14px;

    font-family: var(--input-font-family);
    font-size: var(--input-font-size);
    line-height: var(--input-line-height);
    font-weight: var(--input-font-weight);
    color: var(--color-gray-4);

    border: var(--input-border-width) solid var(--input-without-border-color);
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    resize: none;
    cursor: pointer;

    transition: all var(--transition);

    &:hover {
      border-color: var(--input-without-border-color-hover);
      background-color: var(--input-without-bg-color-hover);
    }
    &:active {
      border-color: var(--input-without-border-color-active);
      background-color: transparent;
    }
  }
}
