@import "../../../../assets/sass/mixins.scss";
.banks-or-crypto-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 22px;
  @include space(row-gap, all, level2);

  .button-bank {
    flex: 1 1 100%;

    @include screen(md) {
      flex: 0 1 calc(25% - 17px);
    }
  }
}
