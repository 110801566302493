.btn--small {
  padding: var(--button-size-sm-pd-mob);

  @include screen(md) {
    padding: var(--button-size-sm-pd-tablet);
  }
  @include screen(lg) {
    padding: var(--button-size-sm-pd-desctop);
  }
}
.btn--medium {
  padding: var(--button-size-md-pd-mob);

  @include screen(md) {
    padding: var(--button-size-md-pd-tablet);
  }
  @include screen(lg) {
    padding: var(--button-size-md-pd-desctop);
  }
}
