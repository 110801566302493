@import "../../assets/sass/mixins.scss";
.main {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  box-sizing: border-box;

  padding-top: var(--header-height);

  @include screen(md) {
    min-height: calc(100vh - var(--header-height-md));
    padding-top: var(--header-height-md);
  }
  @include screen(md) {
    min-height: calc(100vh - var(--header-height-lg));
    padding-top: var(--header-height-lg);
  }
}
