.btn--tertiary {
  padding: var(--button-tertiary-pd);

  color: var(--button-tertiary-color);

  background: var(--button-tertiary-bg);
  border: var(--button-tertiary-border);

  &:hover {
    color: var(--button-tertiary-color-hover);

    background: var(--button-tertiary-bg-hover);
    border: var(--button-tertiary-border-hover);

    .text {
      text-decoration: var(--button-tertiary-text-decoration);
    }
  }
  &:active {
    color: var(--button-tertiary-color-active);

    background: var(--button-tertiary-bg-active);
    border: var(--button-tertiary-border-active);

    .text {
      text-decoration: var(--button-tertiary-text-decoration);
    }
  }
  &:disabled {
    color: var(--button--tertiary-color-disabled);

    background: var(--button--tertiary-bg-color-disabled);
    border: var(--button-tertiary-border-color-disabled);

    .text {
      text-decoration: var(--button-tertiary-text-decoration);
    }
  }
}
