@import "../../../../assets/sass/mixins.scss";
.vertical-offset {
  display: flex;
  flex-direction: column;
}
.vertical-offset--level1 {
  @include space(row-gap, all, level1);
}
.vertical-offset--level2 {
  @include space(row-gap, all, level2);
}
.vertical-offset--level3 {
  @include space(row-gap, all, level3);
}
.vertical-offset--level4 {
  @include space(row-gap, all, level4);
}
.vertical-offset--level5 {
  @include space(row-gap, all, level5);
}
.vertical-offset--level6 {
  @include space(row-gap, all, level6);
}
.vertical-offset--level7 {
  @include space(row-gap, all, level7);
}
.vertical-offset--level8 {
  @include space(row-gap, all, level8);
}
.vertical-offset--level9 {
  @include space(row-gap, all, level9);
}
.vertical-offset--level10 {
  @include space(row-gap, all, level10);
}
