@import "../../../../assets/sass/mixins.scss";

.btn--full {
  width: 100%;
}
.btn--auto {
  width: auto;
}
.btn--full-mobile {
  width: 100%;

  @include screen(sm) {
    width: auto;
  }
}
