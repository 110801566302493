@import "../../../../assets/sass/mixins.scss";
/* Size */
.text--size-h1 {
  font-family: var(--font-main);
  font-size: var(--font-size-h1-mob);
  line-height: var(--font-lh-h1-mob);
  font-weight: 600;

  @include screen(md) {
    font-size: var(--font-size-h1-tablet);
    line-height: var(--font-lh-h1-tablet);
  }
  @include screen(lg) {
    font-size: var(--font-size-h1-desktop);
    line-height: var(--font-lh-h1-desktop);
  }
}
.text--size-h2 {
  font-family: var(--font-main);
  font-size: var(--font-size-h2-mob);
  line-height: var(--font-lh-h2-mob);
  font-weight: 600;

  @include screen(md) {
    font-size: var(--font-size-h2-tablet);
    line-height: var(--font-lh-h2-tablet);
  }
  @include screen(lg) {
    font-size: var(--font-size-h2-desktop);
    line-height: var(--font-lh-h2-desktop);
  }
}
.text--size-h3 {
  font-family: var(--font-main);
  font-size: var(--font-size-h3-mob);
  line-height: var(--font-lh-h3-mob);
  font-weight: 600;

  @include screen(md) {
    font-size: var(--font-size-h3-tablet);
    line-height: var(--font-lh-h3-tablet);
  }
  @include screen(lg) {
    font-size: var(--font-size-h3-desktop);
    line-height: var(--font-lh-h3-desktop);
  }
}
.text--size-h4 {
  font-family: var(--font-main);
  font-size: var(--font-size-h4-mob);
  line-height: var(--font-lh-h4-mob);
  font-weight: 600;

  @include screen(md) {
    font-size: var(--font-size-h4-tablet);
    line-height: var(--font-lh-h4-tablet);
  }
  @include screen(lg) {
    font-size: var(--font-size-h4-desktop);
    line-height: var(--font-lh-h4-desktop);
  }
}
.text--size-body-regular-xl {
  font-family: var(--font-main);
  font-size: var(--font-size-body-regular-xl-mob);
  line-height: var(--font-lh-body-regular-xl-mob);
  font-weight: normal;

  @include screen(md) {
    font-size: var(--font-size-body-regular-xl-tablet);
    line-height: var(--font-lh-body-regular-xl-tablet);
  }
  @include screen(lg) {
    font-size: var(--font-size-body-regular-xl-desktop);
    line-height: var(--font-lh-body-regular-xl-desktop);
  }
}
.text--size-body-regular-l {
  font-family: var(--font-main);
  font-size: var(--font-size-body-regular-l-mob);
  line-height: var(--font-lh-body-regular-l-mob);
  font-weight: normal;

  @include screen(md) {
    font-size: var(--font-size-body-regular-l-tablet);
    line-height: var(--font-lh-body-regular-l-tablet);
  }
  @include screen(lg) {
    font-size: var(--font-size-body-regular-l-desktop);
    line-height: var(--font-lh-body-regular-l-desktop);
  }
}
.text--size-body-semibold-l {
  font-family: var(--font-main);
  font-size: var(--font-size-body-semibold-l-mob);
  line-height: var(--font-lh-body-semibold-l-mob);
  font-weight: 600;

  @include screen(md) {
    font-size: var(--font-size-body-semibold-l-tablet);
    line-height: var(--font-lh-body-semibold-l-tablet);
  }
  @include screen(lg) {
    font-size: var(--font-size-body-semibold-l-desktop);
    line-height: var(--font-lh-body-semibold-l-desktop);
  }
}
.text--size-body-regular-m {
  font-family: var(--font-main);
  font-size: var(--font-size-body-regular-m-mob);
  line-height: var(--font-lh-body-regular-m-mob);
  font-weight: normal;

  @include screen(md) {
    font-size: var(--font-size-body-regular-m-tablet);
    line-height: var(--font-lh-body-regular-m-tablet);
  }
  @include screen(lg) {
    font-size: var(--font-size-body-regular-m-desktop);
    line-height: var(--font-lh-body-regular-m-desktop);
  }
}
.text--size-body-semibold-m {
  font-family: var(--font-main);
  font-size: var(--font-size-body-semibold-m-mob);
  line-height: var(--font-lh-body-semibold-m-mob);
  font-weight: 600;

  @include screen(md) {
    font-size: var(--font-size-body-semibold-m-tablet);
    line-height: var(--font-lh-body-semibold-m-tablet);
  }
  @include screen(lg) {
    font-size: var(--font-size-body-semibold-m-desktop);
    line-height: var(--font-lh-body-semibold-m-desktop);
  }
}
.text--size-body-semibold-xs {
  font-family: var(--font-main);
  font-size: var(--font-size-body-semibold-xs-mob);
  line-height: var(--font-lh-body-semibold-xs-mob);
  font-weight: 600;

  @include screen(md) {
    font-size: var(--font-size-body-semibold-xs-tablet);
    line-height: var(--font-lh-body-semibold-xs-tablet);
  }
  @include screen(lg) {
    font-size: var(--font-size-body-semibold-xs-desktop);
    line-height: var(--font-lh-body-semibold-xs-desktop);
  }
}
.text--size-avatar-s {
  font-family: var(--font-main);
  font-size: var(--font-size-avatar-s-mob);
  line-height: var(--font-lh-avatar-s-mob);
  font-weight: 600;

  @include screen(md) {
    font-size: var(--font-size-avatar-s-tablet);
    line-height: var(--font-lh-avatar-s-tablet);
  }
  @include screen(lg) {
    font-size: var(--font-size-avatar-s-desktop);
    line-height: var(--font-lh-avatar-s-desktop);
  }
}
.text--size-avatar-m {
  font-family: var(--font-main);
  font-size: var(--font-size-avatar-m-mob);
  line-height: var(--font-lh-avatar-m-mob);
  font-weight: 600;

  @include screen(md) {
    font-size: var(--font-size-avatar-m-tablet);
    line-height: var(--font-lh-avatar-m-tablet);
  }
  @include screen(lg) {
    font-size: var(--font-size-avatar-m-desktop);
    line-height: var(--font-lh-avatar-m-desktop);
  }
}
.text--size-avatar-l {
  font-family: var(--font-main);
  font-size: var(--font-size-avatar-l-mob);
  line-height: var(--font-lh-avatar-l-mob);
  font-weight: 600;

  @include screen(md) {
    font-size: var(--font-size-avatar-l-tablet);
    line-height: var(--font-lh-avatar-l-tablet);
  }
  @include screen(lg) {
    font-size: var(--font-size-avatar-l-desktop);
    line-height: var(--font-lh-avatar-l-desktop);
  }
}
