.icon {
  display: inline-flex;
  fill: var(--icon-fill-base);

  &--fill-none {
    fill: none;
  }
  &--fill-white {
    fill: var(--color-white);
  }
  &--stroke-none {
    stroke: none;
  }
  &--stroke-primary {
    stroke: var(--icon-stroke-base);
  }
  &--stroke-white {
    stroke: var(--color-white);
  }
  &--size-base {
    width: var(--icon-size-base);
    height: var(--icon-size-base);
  }
  &--size-esm {
    width: var(--icon-size-esm);
    height: var(--icon-size-esm);
  }
  &--size-sm {
    width: var(--icon-size-sm);
    height: var(--icon-size-sm);
  }
  &--size-md {
    width: var(--icon-size-md);
    height: var(--icon-size-md);
  }
  &--size-lg {
    width: var(--icon-size-lg);
    height: var(--icon-size-lg);
  }
  &--size-xl {
    width: var(--icon-size-xl);
    height: var(--icon-size-xl);
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: baseline;
    padding: 20px;
  }
  &__list-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    font-family: var(--font-main);
    font-size: 14px;
    line-height: 16px;
    color: var(--color-text);
  }
}
