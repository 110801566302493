@import "../../../../assets/sass/mixins.scss";
.row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  flex-direction: row;

  margin-left: calc(-1 * var(--bases-gutter-mob-x));
  margin-right: calc(-1 * var(--bases-gutter-mob-x));

  @include screen(lg) {
    margin-left: calc(-1 * var(--bases-gutter-x));
    margin-right: calc(-1 * var(--bases-gutter-x));
  }
}
.row--no-gutters {
  margin-left: 0;
  margin-right: 0;

  & > [class*="column-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

@import "./row-styles/row-gap.scss";
@import "./row-styles/column-gap.scss";
@import "./row-styles/row-direction.scss";
@import "./row-styles/row-align.scss";
@import "./row-styles/row-justify.scss";
