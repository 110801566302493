@import "../../assets/sass/mixins.scss";
.payment-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 14px;
  column-gap: 14px;

  @include screen(lg) {
    flex-direction: row;
    align-items: center;
  }

  &__icon {
    @include screen(lg) {
      align-self: flex-start;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    text-align: center;

    @include screen(lg) {
      text-align: left;
    }
  }
}
