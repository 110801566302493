.input {
  position: relative;

  display: flex;
  flex-direction: column;
  row-gap: var(--input-row-gap);
  width: 100%;

  &__label {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: var(--input-label-gap);
    box-sizing: border-box;
  }
  &__body {
    position: relative;

    display: flex;
    align-items: center;
    box-sizing: border-box;
    column-gap: var(--input-body-column-gap);
    padding: var(--input-body-padding);
    height: var(--input-body-height);

    color: var(--input-font-color);

    border: var(--input-border-width) solid var(--input-border-color);
    border-radius: var(--input-border-radius);
    background-color: var(--input-bg-color);
    cursor: pointer;
    outline: none;

    transition: all var(--transition);

    &:hover {
      border-color: var(--input-border-color-hover);
    }
    &:focus-within {
      border-color: var(--input-border-color-hover);
    }
    &:active {
      border-color: var(--input-border-color-active);
    }
  }
  &__body--disabled {
    color: var(--input-disabled-color);

    cursor: not-allowed;
    background-color: var(--input-disabled-bg-color);
    border-color: var(--input-disabled-border-color);

    &:hover {
      border-color: var(--input-disabled-border-color);
    }
    &:focus-within {
      border-color: var(--input-disabled-border-color);
    }
    .input__input {
      cursor: not-allowed;

      user-select: none;
    }
    .input__icon .icon {
      fill: var(--input-disabled-icon-fill);
    }
  }
  &__input {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    font-family: var(--input-font-family);
    font-size: var(--input-font-size);
    line-height: var(--input-line-height);
    font-weight: var(--input-font-weight);
    color: currentColor;

    outline: none;

    cursor: pointer;
    background-color: transparent;

    &::-webkit-input-placeholder {
      color: var(--input-placeholder-color);
    }
    &::-moz-placeholder {
      color: var(--input-placeholder-color);
      text-overflow: ellipsis;

      opacity: 1;
    }
    &:-moz-placeholder {
      color: var(--input-placeholder-color);
      text-overflow: ellipsis;

      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: var(--input-placeholder-color);
      text-overflow: ellipsis;
    }
    &[placeholder] {
      text-overflow: ellipsis;
    }
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      fill: var(--input-icon-fill);
      flex: 0 0 auto;
    }
  }
}
.input--error {
  .input__body {
    border-color: var(--input-error-border-color);
  }
  .input__error {
    .icon {
      fill: var(--input-error-icon-fill);
    }
  }
}

@import "--without/--without.scss";
// .input input:disabled {
// 	/* 3 Typography */
// 	color: gray;

// 	/* 4 Visual */
// 	cursor: not-allowed;
// 	background-color: #ffffff;
// 	border: 3px solid pink;
// }
