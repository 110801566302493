@import "../../../assets/sass/mixins.scss";
.table-data {
  display: flex;
  flex-direction: column;
  row-gap: var(--table-data-row-gap);
  box-sizing: border-box;
  width: 100%;
  padding: var(--table-data-pd-mob);

  background-color: var(--table-data-bg-color);
  border-radius: var(--table-data-border-r);

  @include screen(md) {
    padding: var(--table-data-pd-md);
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: var(--table-data-item-column-gap);
  }
  &__item-name {
    @include screen(md) {
      width: var(--table-data-item-name-width-md);
      flex: 0 0 var(--table-data-item-name-width-md);
      overflow-wrap: break-word;
    }
  }
  &__item-value {
    @include screen(mobile) {
      text-align: right;
    }
    @include screen(md) {
      flex: 1 1 auto;
    }
    .text {
      @include screen(mobile) {
        max-width: var(--table-data-item-value-text-width);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

// @include screen(md) {
// }
