@import "../../assets/sass/mixins.scss";
.chat {
  position: relative;

  padding: 0 14px 14px 14px;

  background-color: var(--color-bg-gray);
  border-radius: 12px;

  &__left {
    @include screen(md) {
      padding-top: var(--space-2xs);
      padding-bottom: var(--space-l);
      flex: 0 0 var(--chat-left-width);

      border-right: var(--chat-border-width) solid var(--chat-border-color);
    }
  }
  &__title {
    margin-bottom: var(--space-3s);

    @include screen(md) {
      display: none;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    row-gap: 28px;

    @include screen(md) {
      row-gap: 24px;
    }
    @include screen(md) {
      row-gap: 20px;
    }
  }
  &__body-wrapper {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 243px);
    overflow-y: auto;
    flex: 1 1 auto;

    @extend .chat-scroll;

    @include screen(md) {
      justify-content: flex-end;
      flex: 1 1 auto;
      width: auto;
      height: 242px;
    }
  }
  &__body-list {
    padding-right: 10px;
    overflow-y: auto;

    @extend .chat-scroll;

    @include screen(md) {
      height: 100%;
      min-height: auto;
      max-height: inherit;
      padding-right: 12px;
    }
    @include screen(lg) {
      padding-right: 16px;
    }
  }
}
.chat-scroll {
  scrollbar-width: thin;
  scrollbar-color: var(--color-blue-main) var(--color-bg-gray);

  &::-webkit-scrollbar {
    width: 3px;
    height: 80%;
  }
  &::-webkit-scrollbar-track {
    background: var(--color-bg-gray);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--color-blue-main);
    border: 1px solid var(--color-blue-main);
    border-radius: 26px;
  }
}
