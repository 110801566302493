.icon-img {
  display: inline-flex;

  &--size-base {
    width: var(--icon-img-size-base);
    height: var(--icon-img-size-base);
  }
  &--size-big {
    width: var(--icon-img-size-big);
    height: var(--icon-img-size-big);
  }
  &--size-sm {
    width: var(--icon-img-size-sm);
    height: var(--icon-img-size-sm);
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: baseline;
    padding: 20px;
  }
  &__list-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    font-family: var(--font-main);
    font-size: 14px;
    line-height: 16px;
    color: var(--color-text);
  }
}
