@import "../../../../assets/sass/mixins.scss";
.chat-list {
  &__body {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 0;
    margin: 0;

    list-style: none;

    @include screen(md) {
      row-gap: 16px;
    }
  }
  &__item {
    display: flex;
    align-items: flex-end;
    column-gap: 12px;
    width: auto;
    min-width: 30%;
    margin-left: 0;
    margin-right: auto;
    box-sizing: border-box;
  }
  &__item--support {
    max-width: 95%;
    justify-content: flex-end;
    flex-direction: row-reverse;

    @include screen(md) {
      min-width: 20%;
      max-width: 48%;
      margin-right: 0;
      margin-left: auto;
    }
  }
  &__item--user {
    max-width: 95%;

    @include screen(md) {
      min-width: 20%;
      max-width: 48%;
    }
  }
  &__item-message {
    box-sizing: border-box;
    padding: 12px;
    max-width: 344px;
    word-wrap: break-word;

    border-radius: 12px;
  }
  &__item-message--support {
    background-color: var(--color-light-blue);
  }
  &__item-message--user {
    background-color: var(--color-light-blue-3);
  }
}
