.btn--secondary {
  color: var(--button-secondary-color);

  background: var(--button-secondary-bg);
  border: var(--button-border-width) solid var(--button-secondary-border-color);

  &:hover {
    color: var(--button-secondary-color-hover);

    background: var(--button-secondary-bg-hover);
    border-color: var(--button-secondary-border-color-hover);
  }
  &:active {
    color: var(--button-secondary-color-active);

    background: var(--button-secondary-bg-active);
    border-color: var(--button-secondary-border-color-active);
  }
  &:disabled {
    color: var(--button--secondary-color-disabled);

    background: var(--button--secondary-bg-color-disabled);
    border-color: var(--button-secondary-border-color-disabled);
  }
}
