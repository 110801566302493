.input--without {
  .input__body {
    height: var(--input-without-body-height);

    border-color: var(--input-without-border-color);
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;

    &:hover {
      border-color: var(--input-without-border-color-hover);
      background-color: var(--input-without-bg-color-hover);
    }
    &:focus-within {
      border-color: var(--input-without-border-color-hover);
      background-color: var(--input-without-bg-color-hover);
    }
    &:active {
      border-color: var(--input-without-border-color-active);
      background-color: transparent;
    }
  }
  .input__body--disabled {
    &:hover {
      border-color: var(--input-without-disabled-border-color);
      background-color: var(--input-without-disabled-bg-color);
    }
    &:focus-within {
      border-color: var(--input-without-disabled-border-color);
      background-color: var(--input-without-disabled-bg-color);
    }
  }
}
.input--error.input--without {
  .input__body {
    border-color: var(--input-without-error-border-color);
  }
}
