.message {
  box-sizing: border-box;
  padding: var(--message-pd);

  background-color: var(--message-bg-color);
  border-radius: var(--message-border-r);

  display: flex;
  flex-direction: column;
  gap: var(--message-gap);
}
