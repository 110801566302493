@import "./fonts.scss";
@import "./variables.scss";
@import "./mixins.scss";

/* Clear */
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  border: 0;
}
a {
  text-decoration: none;
  color: var(--link-color);
}
ul {
  padding: 0;
  margin: 0;

  list-style: none;
}
ol,
li {
  padding: 0;
  margin: 0;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}
html,
body {
  height: 100%;
  line-height: 1;

  scrollbar-width: thin;
  scrollbar-color: var(--background-base) var(--background-three);

  &::-webkit-scrollbar {
    width: 3px;
    height: 80%;
  }
  &::-webkit-scrollbar-track {
    background: var(--background-three);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--background-base);
    border: 1px solid var(--background-base);
  }
}
body {
  font-family: var(--font-main);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  color: var(--body-color);

  background-color: var(--body-bg-color);
}
.sb-show-main.sb-main-padded {
  padding: 0;
}
.body-stories {
  padding: 20px;
}
.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  white-space: nowrap;
  border: 0;
  clip-path: inset(100%);
}
