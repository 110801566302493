.divider {
  width: 100%;
  height: var(--divider-height);
  flex: 1 1 auto;

  &--type-solid {
    background-color: var(--divider-solid-bg-color);
  }
  &--type-dash {
    background-image: url("../../../assets/img/divider-dash-bg.svg");
    background-repeat: repeat;
    background-position: left;
  }
}
