@import "../../../assets/sass/mixins.scss";
.img {
  display: block;

  &--size-auto {
    width: auto;
  }
  &--size-full {
    width: 100%;
  }
  &--size-logo {
    width: var(--img-size-logo);
  }
  &--size-icon {
    width: var(--icon-size-base);
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: normal;
    padding: 20px;
  }
  &__list-item {
    display: flex;
  }
}
