@import "../../../assets/sass/mixins.scss";

.button-bank {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: var(--button-bank-height-mob);
  padding: var(--button-bank-pd-mob);
  margin: 0;

  text-decoration: none;
  vertical-align: middle;

  cursor: pointer;
  user-select: none;
  outline: none;
  background-color: var(--button-bank-bg-color);
  border: 1px solid var(--button-bank-border-color);
  border-radius: var(--button-bank-border-r);

  transition: all var(--transition) ease;

  &:hover {
    border-color: var(--button-bank-hover-border-color);
  }

  &:active {
    background-color: var(--button-bank-active-bg-color);
    border-color: var(--button-bank-active-border-color);
  }

  @include screen(md) {
    height: var(--button-bank-height-md);
    padding: var(--button-bank-pd-md);
  }

  @include screen(lg) {
    height: var(--button-bank-height-lg);
    padding: var(--button-bank-pd-lg);
  }

  &--selected {
    background-color: var(--button-bank-active-bg-color);
    border: 1px solid var(--color-blue-main);
  }

  &>img::after {
    content: attr(alt);
    z-index: 1;
    color: black;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../../assets/img/img-visamastercard.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: var(--button-bank-bg-color);
  }
}

// временный класс пока нет иконок больших
.button-bank--icon-text {
  flex-direction: column;
  row-gap: 4px;
  align-items: center;

  .text {
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 100px;
  }
}