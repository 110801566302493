:root {
  /* Base */

  /* Primary colors */
  --color-blue-main: #2d75fe;
  --color-blue-hover: #459bff;
  --color-blue-active: #2658b8;
  --color-black: #000000;
  --color-white: #ffffff;

  /* Neutral colors */
  --color-light-blue: #c1d7ff;
  --color-light-blue-2: #e8f0ff;
  --color-light-blue-3: #eef1f8;
  --color-gray-1: #f6f6f6;
  --color-gray-2: #eeeeee;
  --color-gray-3: #d9d9d9;
  --color-gray-4: #bcc0c1;
  --color-gray-5: #57596c;
  --color-bg-gray: #fafafa;
  --color-text: #333750;

  /* Other colors */
  --color-red: #e73636;
  --color-yellow: #f2c94c;
  --color-orange: #f56b00;
  --color-green: #29d570;
  --color-light-red: #ffe8e8;

  /* Base Shadow */
  --shadow-big: 0px 17px 34px rgba(51, 51, 51, 0.07);
  --card-shadow: inset 0px -3px 0px rgba(55, 148, 219, 0.3);

  /* Base Fonts */
  --font-main: "Inter", "Sans Serif";

  /* Base Fonts H1  */
  --font-size-h1-desktop: 52px;
  --font-lh-h1-desktop: 64px;
  --font-size-h1-tablet: 38px;
  --font-lh-h1-tablet: 48px;
  --font-size-h1-mob: 34px;
  --font-lh-h1-mob: 42px;

  /* Base Fonts H2  */
  --font-size-h2-desktop: 36px;
  --font-lh-h2-desktop: 44px;
  --font-size-h2-tablet: 32px;
  --font-lh-h2-tablet: 40px;
  --font-size-h2-mob: 26px;
  --font-lh-h2-mob: 34px;

  /* Base Fonts H3  */
  --font-size-h3-desktop: 30px;
  --font-lh-h3-desktop: 38px;
  --font-size-h3-tablet: 28px;
  --font-lh-h3-tablet: 36px;
  --font-size-h3-mob: 22px;
  --font-lh-h3-mob: 28px;

  /* Base Fonts H4  */
  --font-size-h4-desktop: 24px;
  --font-lh-h4-desktop: 34px;
  --font-size-h4-tablet: 20px;
  --font-lh-h4-tablet: 28px;
  --font-size-h4-mob: 18px;
  --font-lh-h4-mob: 24px;

  /* Base Fonts Body Regular Xl */
  --font-size-body-regular-xl-desktop: 24px;
  --font-lh-body-regular-xl-desktop: 30px;
  --font-size-body-regular-xl-tablet: 24px;
  --font-lh-body-regular-xl-tablet: 30px;
  --font-size-body-regular-xl-mob: 20px;
  --font-lh-body-regular-xl-mob: 25px;

  /* Base Fonts Body Regular L */
  --font-size-body-regular-l-desktop: 18px;
  --font-lh-body-regular-l-desktop: 24px;
  --font-size-body-regular-l-tablet: 18px;
  --font-lh-body-regular-l-tablet: 22px;
  --font-size-body-regular-l-mob: 16px;
  --font-lh-body-regular-l-mob: 20px;

  /* Base Fonts Body Semibold L */
  --font-size-body-semibold-l-desktop: 18px;
  --font-lh-body-semibold-l-desktop: 24px;
  --font-size-body-semibold-l-tablet: 18px;
  --font-lh-body-semibold-l-tablet: 22px;
  --font-size-body-semibold-l-mob: 16px;
  --font-lh-body-semibold-l-mob: 20px;

  /* Base Fonts Body Regular M */
  --font-size-body-regular-m-desktop: 14px;
  --font-lh-body-regular-m-desktop: 18px;
  --font-size-body-regular-m-tablet: 14px;
  --font-lh-body-regular-m-tablet: 18px;
  --font-size-body-regular-m-mob: 14px;
  --font-lh-body-regular-m-mob: 18px;

  /* Base Fonts Body Semibold M */
  --font-size-body-semibold-m-desktop: 14px;
  --font-lh-body-semibold-m-desktop: 18px;
  --font-size-body-semibold-m-tablet: 14px;
  --font-lh-body-semibold-m-tablet: 18px;
  --font-size-body-semibold-m-mob: 14px;
  --font-lh-body-semibold-m-mob: 18px;

  /* Base Fonts Body Semibold Xs */
  --font-size-body-semibold-xs-desktop: 10px;
  --font-lh-body-semibold-xs-desktop: 12px;
  --font-size-body-semibold-xs-tablet: 10px;
  --font-lh-body-semibold-xs-tablet: 12px;
  --font-size-body-semibold-xs-mob: 10px;
  --font-lh-body-semibold-xs-mob: 12px;

  /* Base Fonts Avatar S */
  --font-size-avatar-s-mob: 10px;
  --font-lh-avatar-s-mob: 12px;
  --font-size-avatar-s-tablet: 10px;
  --font-lh-avatar-s-tablet: 12px;
  --font-size-avatar-s-desktop: 10px;
  --font-lh-avatar-s-desktop: 12px;

  /* Base Fonts Avatar M */
  --font-size-avatar-m-mob: 14px;
  --font-lh-avatar-m-mob: 18px;
  --font-size-avatar-m-tablet: 14px;
  --font-lh-avatar-m-tablet: 18px;
  --font-size-avatar-m-desktop: 14px;
  --font-lh-avatar-m-desktop: 18px;

  /* Base Fonts Avatar L */
  --font-size-avatar-l-mob: 18px;
  --font-lh-avatar-l-mob: 24px;
  --font-size-avatar-l-tablet: 18px;
  --font-lh-avatar-l-tablet: 24px;
  --font-size-avatar-l-desktop: 18px;
  --font-lh-avatar-l-desktop: 24px;

  /* Base Fonts Color */
  --font-color-primary: var(--color-text);
  --font-color-secondary: var(--color-blue-main);
  --font-color-third: var(--color-white);
  --font-color-four: var(--color-gray-4);
  --font-color-danger: var(--color-red);

  /* Base Body */
  --body-font-size: 14px;
  --body-line-height: 18px;
  --body-color: var(--color-text);
  --body-bg-color: var(--color-bg-gray);

  /* Base Grid */
  --container-width-media-sm: 540px;
  --container-width-media-md: 754px;
  --container-width-media-lg: 930px;
  --container-width-media-xl: 930px;
  --container-width: 930px;

  --bases-gutter-mob-x: 10px;
  --bases-gutter-x: 10px;

  // /* Border */
  // --border-radius-base: 8px;
  // --border-radius-md: 12px;
  // --border-radius-lg: 16px;
  // --border-radius-xl: 26px;
  // --border-width-base: 1px;
  // --border-width-m: 2px;
  // --border-color-base: var(--color-button-border-default);
  // --border-color-secondary: var(--color-blue);

  // /* Background */
  // --background-base: var(--color-blue);
  // --background-secondary: var(--color-white);
  // --background-three: var(--color-disable-background);
  // --background-four: #e7ebf6;
  // --background-five: #e5ecff;

  /* Transition */
  --transition: 0.4s;

  /* UI */

  /* Avatar */
  --avatar-size: 28px;
  --avatar-size-s: 24px;
  --avatar-size-m: 32px;
  --avatar-size-l: 40px;
  --avatar-bg-color: var(--color-gray-3);
  --avatar-bg-color-blue: var(--color-blue-main);
  --avatar-bg-color-orange: var(--color-orange);
  --avatar-bg-color-yellow: var(--color-yellow);
  --avatar-bg-color-red: var(--color-red);
  --avatar-bg-color-green: var(--color-green);

  /* Badge */
  --badge-pd: 6px 14px;
  --badge-bg-color: var(--color-light-blue-2);
  --badge-border-r: 26px;

  /* Button */
  --button-padding: 13px 16px;
  --button-column-gap: 8px;

  --button-font-family: var(--font-main);
  --button-font-size: 14px;
  --button-line-height: 18px;
  --button-color: var(--color-white);
  --button-background: var(--color-blue-main);
  --button-border-width: 1px;
  --button-border-color: var(--color-blue-main);
  --button-border-radius: 6px;
  --button-icon-fill: var(--color-white);

  --button-border-color-hover: var(--color-blue-hover);
  --button-background-color-hover: var(--color-blue-hover);

  --button-border-color-active: var(--color-blue-active);
  --button-background-color-active: var(--color-blue-active);

  --button-border-color-disabled: var(--color-gray-3);
  --button-background-color-disabled: var(--color-gray-3);
  --button-color-disabled: var(--color-gray-4);
  --button-icon-fill-disabled: var(--color-gray-4);

  /* Button Small */
  --button-size-sm-pd-mob: 10px 12px;
  --button-size-sm-pd-tablet: 10px 16px;
  --button-size-sm-pd-desctop: 12px 18px;

  /* Button Medium */
  --button-size-md-pd-mob: 13px 34px;
  --button-size-md-pd-tablet: 13px 40px;
  --button-size-md-pd-desctop: 14px 40px;

  /* Button Secondary */
  --button-secondary-color: var(--color-blue-main);
  --button-secondary-bg: transparent;
  --button-secondary-border-color: var(--color-blue-main);

  --button-secondary-color-hover: var(--color-white);
  --button-secondary-bg-hover: var(--color-blue-main);
  --button-secondary-border-color-hover: var(--color-blue-main);

  --button-secondary-color-active: var(--color-white);
  --button-secondary-bg-active: var(--color-blue-active);
  --button-secondary-border-color-active: var(--color-blue-active);

  --button--secondary-color-disabled: var(--color-gray-4);
  --button--secondary-bg-color-disabled: transparent;
  --button-secondary-border-color-disabled: var(--color-gray-3);

  /* Button Tertiary */
  --button-tertiary-pd: 0;
  --button-tertiary-color: var(--color-blue-main);
  --button-tertiary-bg: none;
  --button-tertiary-border: none;
  --button-tertiary-text-decoration: underline;

  --button-tertiary-color-hover: var(--color-blue-hover);
  --button-tertiary-bg-hover: none;
  --button-tertiary-border-hover: none;

  --button-tertiary-color-active: var(--color-blue-active);
  --button-tertiary-bg-active: none;
  --button-tertiary-border-active: none;

  --button--tertiary-color-disabled: var(--color-gray-4);
  --button--tertiary-bg-color-disabled: none;
  --button-tertiary-border-disabled: none;

  /* Button Only Icon */
  --button-only-icon-pd: 9px;

  /* Button Bank */
  --button-bank-height-mob: 50px;
  --button-bank-height-md: 62px;
  --button-bank-height-lg: 80px;
  --button-bank-pd-mob: 0 28px;
  --button-bank-pd-md: 0 25px;
  --button-bank-pd-lg: 0 47px;
  --button-bank-bg-color: var(--color-white);
  --button-bank-border-color: var(--color-gray-2);
  --button-bank-border-r: 8px;
  --button-bank-hover-border-color: var(--color-blue-hover);
  --button-bank-active-border-color: var(--color-blue-main);
  --button-bank-active-bg-color: var(--color-light-blue-2);

  // /* Body */
  // --body-background-color: var(--background-three);

  /* Checkbox */
  --checkbox-size: 20px;
  --checkbox-color: var(--color-text);
  --checkbox-border-width: 1px;
  --checkbox-border-color: var(--color-gray-3);
  --checkbox-border-radius: 3px;
  --checkbox-width-before: 10px;
  --checkbox-height-before: 8px;
  --checkbox-label-column-gap: 8px;

  --checkbox-border-color-hover: var(--color-blue-active);

  --checkbox-bg-color-disabled: var(--color-gray-1);
  --checkbox-border-color-disabled: var(--color-gray-3);

  --checkbox-background-color-checked: var(--color-blue-main);
  --checkbox-border-color-checked: var(--color-blue-main);
  --checkbox-background-color-checked-hover: var(--color-blue-active);
  --checkbox-border-color-checked-hover: var(--color-blue-active);

  --checkbox-background-color-checked-disabled: var(--color-gray-3);
  --checkbox-border-color-checked-disabled: var(--color-gray-3);

  /* Copy Text */
  --copy-text-pd: 13px;
  --copy-text-body-column-gap: 14px;
  --copy-text-bg-color: var(--color-bg-gray);
  --copy-text-button-flex: 24px;

  // /* Calendar Custom */
  // --calendar-custom-label-height: 24px;
  // --calendar-custom-day-height: 32px;
  // --calendar-custom-font-size: 16px;
  // --calendar-custom-line-height: 24px;
  // --calendar-custom-calendar-width: 290px;
  // --calendar-custom-navigation-mb: 10px;
  // --calendar-custom-navigation-arrow-hover: var(--color-blue-bg-mat);
  // --calendar-custom-navigation-arrow-icon-fill-hover: var(--color-blue);
  // --calendar-custom-navigation-arrow-icon-fill: var(--color-button-text-secondary);
  // --calendar-custom-navigation-border-width: var(--border-width-base);
  // --calendar-custom-navigation-border-color: var(--border-color-base);
  // --calendar-custom-navigation-border-radius: var(--border-radius-base);
  // --calendar-custom-view-container-bg-color: var(--background-secondary);
  // --calendar-custom-month-view-weekdays-weekday: var(--color-text-secondary);
  // --calendar-custom-month-view-days-day-weekend: #eb5757;
  // --calendar-custom-month-view-days-day-neighboringMonth: var(--color-disable);
  // --calendar-custom-month-view-days-day-neighboringMonth-disable: var(--color-red-disable);
  // --calendar-custom-tile-active-color: var(--color-text);
  // --calendar-custom-tile-bg-color: var(--background-base);
  // --calendar-custom-tile-color: var(--color-white);
  // --calendar-custom-tile-border-radius: 4px;
  // --calendar-custom-decade-century-year-height: 32px;
  // --calendar-custom-century-view-decades-decad-height: 56px;
  // --calendar-custom-decade-year-view-color: var(--color-text);
  // --calendar-custom-decade-year-view-color-hover: var(--color-blue-hover);

  /* Divider */
  --divider-height: 1px;
  --divider-solid-bg-color: var(--color-gray-3);

  // /* Dropdown Quantity */
  // --dropdown-quantity-column-gap: var(--space-3s);
  // --dropdown-quantity-text-color: var(--color-text);
  // --dropdown-quantity-font-size: var(--font-size-16);
  // --dropdown-quantity-line-height: var(--font-line-height-24);

  // --dropdown-quantity-border-radius: var(--border-radius-base);
  // --dropdown-quantity-border-color: var(--border-color-base);
  // --dropdown-quantity-border-width: var(--border-width-base);
  // --dropdown-quantity-bg-color: var(--background-secondary);
  // --dropdown-quantity-border-color-active: var(--border-color-secondary);

  // --dropdown-quantity-control-pd: var(--space-2s) var(--space-2s) var(--space-2s) var(--space-3s);
  // --dropdown-quantity-control-height: 50px;
  // --dropdown-quantity-control-min-width: 88px;

  // --dropdown-quantity-indicator-pd: 0 0 0 var(--space-xs);
  // --dropdown-quantity-indicator-icon-fill: var(--color-text-secondary);
  // --dropdown-quantity-indicator-icon-fill-hover: var(--color-blue);

  // --dropdown-quantity-menu-list-min-height: 50px;
  // --dropdown-quantity-menu-list-max-height: 450px;
  // --dropdown-quantity-option-pd: 14px 16px;

  // /* Hamburger */
  // --hamburger-width: 24px;
  // --hamburger-height: 24px;
  // --hamburger-body-width: 18px;
  // --hamburger-body-height: 12px;
  // --hamburger-band-height: 13%;
  // --hamburger-background-color: var(--color-text);
  // --hamburger-background-color-hover: var(--color-blue-hover);

  /* QR Code */

  /* Icon */
  --icon-size-base: 24px;
  --icon-size-esm: 9px;
  --icon-size-sm: 18px;
  --icon-size-md: 30px;
  --icon-size-lg: 36px;
  --icon-size-xl: 40px;
  --icon-fill-base: var(--color-gray-5);
  --icon-fill-bg-icon: var(--color-gray-3);
  --icon-stroke-base: var(--color-gray-5);

  /* Icon Img */
  --icon-img-size-base: 24px;
  --icon-img-size-sm: 18px;
  --icon-img-size-big: 40px;

  /* Icon-Text */
  --icon-text-gap-esm: 2px;
  --icon-text-gap-sm: 4px;
  --icon-text-gap-m: 6px;
  --icon-text-gap-lg: 8px;
  --icon-text-gap-xl: 10px;
  --icon-text-gap-2xl: 12px;

  /* Img */
  --img-size-logo: 100px;

  /* Input */
  --input-row-gap: 8px;
  --input-label-gap: 8px;
  --input-body-height: 44px;
  --input-body-padding: 0 14px;
  --input-body-column-gap: 8px;

  --input-border-width: 1px;
  --input-border-color: var(--color-gray-2);
  --input-border-radius: 4px;
  --input-bg-color: var(--color-white);
  --input-icon-fill: var(--color-gray-5);

  --input-font-family: var(--font-main);
  --input-font-size: 14px;
  --input-line-height: 18px;
  --input-font-weight: 400;
  --input-font-color: var(--color-text);
  --input-placeholder-color: var(--color-gray-4);

  --input-border-color-hover: var(--color-light-blue);
  --input-border-color-active: var(--color-blue-main);

  --input-disabled-color: var(--color-gray-4);
  --input-disabled-bg-color: var(--color-gray-1);
  --input-disabled-border-color: var(--color-gray-4);
  --input-disabled-icon-fill: var(--color-gray-4);

  --input-error-border-color: var(--color-red);
  --input-error-icon-fill: var(--color-red);

  /* Input Without*/
  --input-without-body-height: 42px;
  --input-without-border-color: var(--color-gray-3);

  --input-without-border-color-hover: var(--color-light-blue);
  --input-without-bg-color-hover: var(--color-light-blue-3);
  --input-without-border-color-active: var(--color-blue-main);

  --input-without-error-border-color: var(--color-red);
  --input-without-disabled-border-color: var(--color-gray-4);
  --input-without-disabled-bg-color: var(--color-gray-1);

  /* Langs */
  --flag-dropdown-control-pd: 8px;
  --flag-dropdown-control-border-color: var(--color-gray-2);
  --flag-dropdown-control-border-r: 2px;
  --flag-dropdown-control-hover-border-color: var(--color-light-blue);
  --flag-dropdown-control-hover-bg-color: var(--color-light-blue-2);
  --flag-dropdown-control-active-border-color: var(--color-light-blue);
  --flag-dropdown-control-active-bg-color: var(--color-light-blue-2);

  --flag-dropdown-indicator-pd: 0;
  --flag-dropdown-indicator-m-l: 10px;
  --flag-dropdown-indicator-icon-fill: var(--color-gray-5);

  --flag-dropdown-menu-bg-color: var(--color-light-blue-2);
  --flag-dropdown-menu-border-color: var(--color-light-blue);
  --flag-dropdown-menu-border-r: 2px;

  --flag-dropdown-menu-m: 0;

  --flag-dropdown-option-pd: 4px 8px;
  --flag-dropdown-bg-color-hover: var(--color-light-blue);

  --langs-option-check-size: 24px;

  /* Link */
  --link-color: var(--color-blue-main);
  --link-hover-color: var(--color-blue-hover);

  /* Logo */
  --logo-width-mob: 117px;
  --logo-width: 144px;

  /* Mark */
  --mark-color-primary: var(--color-blue-main);

  /* Message */
  --message-pd: 14px 18px;
  --message-bg-color: var(--color-bg-gray);
  --message-border-r: 12px;
  --message-gap: 8px;

  // /* Menu */
  // --menu-list-row-gap: var(--space-m);
  // --menu-list-row-gap-xl: var(--space-3m);
  // --menu-icon-fill: var(--color-button-text-secondary);
  // --menu-text-color: var(--color-text-secondary);

  // --menu-icon-fill-active: var(--icon-fill-base);
  // --menu-text-color-active: var(--color-text);

  // /* Modal */
  // --modal-wrapper-width-base: 804px;
  // --modal-wrapper-width-sm: 600px;
  // --modal-content-bg-color-base: var(--background-secondary);
  // --modal-content-padding-base: 22px;
  // --modal-wrapper-padding-base: 0;

  // /* Pagination */
  // --pagination-column-gap: var(--space-m);
  // --pagination-color-hover: var(--color-blue-hover);
  // --pagination-current-color: var(--color-blue-active);

  // /* Radio */
  // --radio-size: 24px;
  // --radio-border-width: var(--border-width-base);
  // --radio-border-color: var(--border-color-base);
  // --radio-border-radius: 50%;
  // --radio-width-before: 12px;
  // --radio-height-before: 12px;
  // --radio-border-radius-before: 50%;

  // --radio-border-color-checked: var(--color-blue);
  // --radio-background-color-before-checked: var(--color-blue);

  // --radio-border-color-checked-hover: var(--color-blue-hover);
  // --radio-background-color-before-checked-hover: var(--color-blue-hover);

  // --radio-border-color-hover: var(--color-blue-hover);
  // --radio-border-color-disabled: var(--color-disable-border);
  // --radio-background-color-disabled: var(--color-disable-background);
  // --radio-background-color-checked-disabled: var(--color-disable);
  // --radio-color-disabled: var(--color-text-secondary);

  /* Table Data */
  --table-data-row-gap: 14px;
  --table-data-pd-mob: 14px 12px;
  --table-data-pd-md: 14px 18px;
  --table-data-bg-color: var(--color-bg-gray);
  --table-data-border-r: 12px;
  --table-data-item-column-gap: 8px;
  --table-data-item-name-width-md: 172px;
  --table-data-item-value-text-width: 200px;

  // /* Select Custom */
  // --select-custom-column-gap: var(--space-3s);
  // --select-custom-text-color: var(--color-text);
  // --select-custom-font-size: var(--font-size-16);
  // --select-custom-line-height: var(--font-line-height-24);

  // --select-custom-border-radius: var(--border-radius-base);
  // --select-custom-border-color: var(--border-color-base);
  // --select-custom-border-width: var(--border-width-base);
  // --select-custom-bg-color: var(--background-secondary);
  // --select-custom-border-color-active: var(--border-color-secondary);

  // --select-custom-control-pd: var(--space-2s) var(--space-2s) var(--space-2s) var(--space-3s);
  // --select-custom-control-height: 50px;
  // --select-custom-control-min-width: 88px;

  // --select-custom-indicator-pd: 0 0 0 var(--space-xs);
  // --select-custom-indicator-icon-fill: var(--color-text-secondary);
  // --select-custom-indicator-icon-fill-hover: var(--color-blue);

  // --select-custom-menu-list-min-height: 50px;
  // --select-custom-menu-list-max-height: 450px;
  // --select-custom-option-pd: 14px 16px;

  // /* Switcher */
  // --switcher-height: 52px;
  // --switcher-border-radius: var(--border-radius-base);
  // --switcher-border-width: var(--border-width-base);
  // --switcher-border-color: var(--color-button-border-default);
  // --switcher-bg: var(--color-disable-background);
  // --switcher-border-color-hover: var(--color-blue-hover);
  // --switcher-bg-hover: var(--color-blue-bg-mat);

  // --switcher-button-padding: 12px 20px;
  // --switcher-button-color: var(--color-button-text-secondary);
  // --switcher-icon-fill: var(--color-button-text-secondary);

  // --switcher-button-color-hover: var(--color-blue-hover);
  // --switcher-icon-fill-hover: var(--color-blue-hover);
  // --switcher-hover-before-bg-color: var(--color-blue-hover);

  // --switcher-button-color-active: var(--color-white);
  // --switcher-bg-active: var(--color-blue);
  // --switcher-button-border-color-active: var(--color-blue-hover);
  // --switcher-button-active-color-hover: var(--color-white);
  // --switcher-button-icon-size: var(--icon-size-base);
  // --switcher-button-text-fz: var(--font-size-14);
  // --switcher-button-text-lh: var(--font-line-height-20);

  // /* Tabs */
  // --tabs-list-column-gap: var(--space-4m);
  // --tabs-list-column-gap-lg: var(--space-2l);

  // --tab-pb: var(--space-s);
  // --tab-color: var(--color-button-text-secondary);
  // --tab-text-column-gap: var(--space-xs);
  // --tab-before-height: 2px;
  // --tab-before-background-color: #2d58b6;

  // --tab-color-hover: var(--color-blue-hover);
  // --tab-color-active: var(--color-blue);
  // --tab-color-disable: var(--color-disable);

  // --tabs-header-select-padding-lg: var(--space-m) var(--space-4m);
  // --tabs-header-select-background-color: var(--background-secondary);
  // --tabs-header-select-border-radius: var(--border-radius-base);

  // /* Text Area */
  // --text-area-height: 52px;
  // --text-area-pd: 14px 16px;
  // --text-area-border-radius: var(--border-radius-base);
  // --text-area-border-width: var(--border-width-base);
  // --text-area-border-color: var(--border-color-base);
  // --text-area: var();
  // --text-area: var();

  // /* Toggle */
  // --toggle-visual-width: 52px;
  // --toggle-visual-height: 28px;

  // --toggle-width-before: 14px;
  // --toggle-height-before: 14px;
  // --toggle-visual-before-position-x: 4px;

  // --toggle-width-before: 20px;
  // --toggle-height-before: 20px;
  // --toggle-background-color-before: var(--color-white);
  // --toggle-border-radius-before: 50%;

  // --toggle-background-color: var(--color-disable);
  // --toggle-border-radius: 30px;

  // --toggle-background-color-hover: var(--color-button-text-secondary);
  // --toggle-background-color-checked: var(--background-base);
  // --toggle-background-color-checked-hover: var(--color-blue-hover);
  // --toggle-background-color-disabled: var(--color-disable);

  // /* Tooltips */
  // --tooltips-icon-fill: var(--color-button-text-secondary);
  // --tooltips-icon-fill-hover: var(--color-blue);
  // --tooltips-content-z-index: 10;
  // --tooltips-content-position-y: 36px;
  // --tooltips-content-position-x: 12px;
  // --tooltips-content-width: 272px;
  // --tooltips-content-padding: 16px;
  // --tooltips-content-color: var(--color-white);
  // --tooltips-content-bg-color: var(--color-blue);
  // --tooltips-content-border-radius: var(--border-radius-base);
  // --tooltips-content-before-size: 12px;
  // --tooltips-content-before-position: 6px;

  /* Components */

  /* Header*/
  --header-height: 79px;
  --header-height-md: 88px;
  --header-height-lg: 86px;
  --header-border-width: 1px;
  --header-border-color: var(--color-gray-2);
  --header-background-color: var(--color-white);

  /* Page */
  --page-body-pd-mob: 24px 18px;
  --page-body-pd-md: 24px 28px;
  --page-body-bg-color: var(--color-white);
  --page-body-border-r: 12px;
  --page-body-box-shadow: var(--shadow-big);
}
